@import url(https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Audiowide&family=Questrial&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Questrial", sans-serif;
}
html,
body {
  overflow-x: hidden;
}
body {
  position: relative;
}
:root {
  --primaryColor: #d03737;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainBlue: #0c1248;
  --mainGrey: #b2b2b2;
  --darkGrey: #cfcfcf;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 1px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
  --whiteOverlay: rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3);
}
/* globals */
body {
  /* padding-top: 66px; */
  color: #222;
  color: var(--mainBlack);
  background: #fff;
  background: var(--mainWhite);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1.4;
  height: 100%;
}
h1 {
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.5em;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 0.8em;
}

.btn-services {
  display: inline-block;
  text-decoration: none;
  letter-spacing: 1px;
  letter-spacing: var(--mainSpacing);
  color: #fff;
  color: var(--mainWhite);
  background: #d03737;
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid #d03737;
  border: 3px solid var(--primaryColor);
  transition: all 0.3s linear;
  transition: var(--mainTransition);
  /* text-transform: uppercase; */
  cursor: pointer;
  width: 50%;
  font-size: 25px;
  margin-top: 3rem;
}

.btn-services:hover {
  background: transparent;
  color: #0c1248;
  color: var(--mainBlue);
}

.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: 1px;
  letter-spacing: var(--mainSpacing);
  color: #fff;
  color: var(--mainWhite);
  background: #d03737 !important;
  background: var(--primaryColor) !important;
  padding: 0.4rem 0.9rem;
  border: 3px solid #d03737 !important;
  border: 3px solid var(--primaryColor) !important;
  transition: all 0.3s linear;
  transition: var(--mainTransition);
  /* text-transform: uppercase; */
  cursor: pointer;
  width: 200px;
  font-size: 25px;
}
.banner .btn-primary:hover {
  background-color: #0c1248 !important;
  background-color: var(--mainBlue) !important;
  color: #d03737;
  color: var(--primaryColor);
}
.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
}
.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}
.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: 1px;
  letter-spacing: var(--mainSpacing);
}
/* end of globals */
/* Navbar */
#logo {
  width: 210px;
  transition: 0.8s;
}

.logoShrink {
  width: 190px !important;
  transition: 0.9s;
}

.navbarBg {
  background: rgba(#818181) !important;
}

.navShadow {
  box-shadow: 0px 5px 7px 0px rgba(12, 18, 72, 0.75);
  background-color: #b2b2b2f2;
}

.newNav {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  /* background-color: var(--primaryColor); */
}
#navbar {
  overflow: hidden;
  padding: 20px 10%;
  transition: 0.4s;
  position: fixed;
  display: flex;
  width: 100%;
  top: 0;
  z-index: 99;
  /* background-color: var(--primaryColor); */
}
.myContainer {
  width: 80%;
}
.pgaLogo {
    display: flex;
    justify-content: center;
    height: 200px;
}
#basic-navbar-nav a {
  text-decoration: none;
}
#basic-navbar-nav a:hover {
  background-color: var(--secondaryColor);
}
.nav-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
}

.nav-link {
  color: #222 !important;
  color: var(--mainBlack) !important;
  font-size: 1.3rem;
  letter-spacing: 1px;
  margin: 1rem 0.5rem;
  font-size: 1.1rem !important;
}
.nav-link:hover {
  color: #d03737 !important;
  color: var(--primaryColor) !important;
  background-color: #0c1248 !important;
  background-color: var(--mainBlue) !important;
  transition: 0.7s;
  border-radius: 8px;
  text-decoration: none !important;
}

.navbar-nav a:hover {
  background-color: var(--secondaryColor);
}

.navbar-light .navbar-toggler-icon {
  background-image: url(/static/media/alignRight.e9f72c73.svg) !important;
  border-color: var(--secondaryColor) !important;
}

.navbar-light .navbar-toggler {
  border-color: rgba(255, 255, 255, 0) !important;
}

.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
.nav-icon {
  font-size: 1.5rem;
  color: var(--secondaryColor);
}
#nav-link {
  height: 0;
  overflow: hidden;
  transition: all 0.3s linear;
  transition: var(--mainTransition);
  list-style-type: none;
  align-items: center;
  font-size: 1.5rem;
  /* padding-left: 300px; */
}
.nav-links a {
  display: block;
  text-decoration: none;
  padding: 1rem;
  color: #fff;
  color: var(--mainWhite);
  transition: all 0.3s linear;
  transition: var(--mainTransition);
  text-align: center;
  /* font-size: 1.8rem; */
  font-weight: 600;
  letter-spacing: 1px;
  letter-spacing: var(--mainSpacing);
}
.nav-links a:hover {
  color: #222;
  color: var(--mainBlack);
  background: var(--secondaryColor);
  text-decoration: none;
}

.show-nav {
  height: 192px;
  padding: 1px;
}

#basic-navbar-nav {
  transition: 2s ease;
}

@media screen and (min-width: 1028px) {
  .nav-btn {
    display: flex;
    /* padding-left: 50%; */
    justify-content: flex-end;
  }
  .nav-center {
    max-width: 100% !important;
    /* margin: 0 auto; */
    display: flex;
  }
  .nav-links {
    height: auto;
    display: flex;
    margin-left: 4rem;
  }
  .nav-links a {
    /* margin: 0 1rem; */
    padding: 1rem;
  }
}
/* end of navbar */
/* Hero */
.defaultHero,
.servicesHero {
  min-height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)),
    url(/static/media/servicesMain.e9e21bb4.jpg);
  background: linear-gradient(var(--whiteOverlay)),
    url(/static/media/servicesMain.e9e21bb4.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 8%;
}
.servicesHero {
  background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)),
    url(/static/media/defaultBcg.0f6a58bb.jpg);
  background: linear-gradient(var(--whiteOverlay)),
    url(/static/media/defaultBcg.0f6a58bb.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
/* End of Hero */
/* Banner */
.banner {
  display: inline-block;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: 1px;
  letter-spacing: var(--mainSpacing);
  width: 100%;
}
.banner h1 {
  font-size: 2.5rem;
  font-family: "Audiowide", cursive;
  padding-top: 2rem;
}
.banner div {
  width: 10rem;
  height: 5px;
  background: #d03737;
  background: var(--primaryColor);
  margin: 1.7rem auto;
}
.banner p {
  font-size: 1.8rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 485px) {
  .banner {
    padding: 2rem 3rem;
  }
  .banner h1 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
  }
  .banner h1 {
    font-size: 4rem;
  }
}
/* End of Banner */
/* Title */
.title br {
  border: solid 1x red;
}
.section-title {
  text-align: center;
  margin-bottom: 1.5rem;
}
.section-title h4 {
  font-size: 3.5rem;
  letter-spacing: 1px;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background: #d03737;
  background: var(--primaryColor);
}
/* end of Title */

/* services */
.services {
  padding: 3rem 0;
}
.services {
  background: #cfcfcf;
  background: var(--darkGrey);
  text-align: center;
}
.services-center {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-row-gap: 1.5rem;
  grid-column-gap: 50px;
}

.service span {
  display: inline-block;
  color: #d03737;
  color: var(--primaryColor);
  font-size: 2.5rem;
  /* margin-bottom: 1.5rem; */
}
.services h6 {
  letter-spacing: 1px;
  letter-spacing: var(--mainSpacing);
  font-size: 2rem;
  padding-bottom: 0 !important;
}
.services p {
  width: 80%;
  margin: 0 auto;
  font-size: 1.5rem;
}

.servicesImg {
  width: 30px;
}

@media screen and (min-width: 320px) {
  .services-center {
    width: 95vw;
    max-width: 800px;
  }
}

@media screen and (min-width: 320px) {
  .services p {
    width: 100%;
  }
}
/*end of services */

/* About Styling */

.about {
  width: 90%;
  margin-top: 10%;
}

.smaller {
  height: 60px !important;
  transition: 0.5s;
}
.underline {
  margin-bottom: 1.5rem;
  border-bottom: 5px solid #d03737;
  border-bottom: 5px solid var(--primaryColor);
  width: 35% !important;
  padding-top: 2%;
  margin-bottom: 10%;
}

.aboutContent {
  background-color: #0c124870;
  padding: 10% 20%;
  margin-right: 10%;
}
.mainServices {
  background-color: #cfcfcf;
  padding-top: 5%;
}
.mainServices .row {
  padding: 5% 0;
}
.golfServices {
  background-color: #cfcfcf;
  padding-top: 0.5%;
}
.golfServices .row {
  padding: 0;
}
.nigelImages {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}
.nigelImages img {
  width: 30%;
  box-shadow: 5px 5px 15px 5px #000000;
}
.descriptionUnderline {
  -webkit-text-decoration: line-through solid;
          text-decoration: line-through solid;
}
.serviceBox {
  color: #999;
  font-family: "Poppins", sans-serif;
  text-align: center;
  padding: 10px 0 15px;
  position: relative;
}
.serviceBox:before,
.serviceBox:after {
  content: "";
  background: #d03737;
  background: var(--primaryColor);
  width: 150px;
  height: 10px;
  transform: translateX(-50%);
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-clip-path: polygon(7% 0%, 93% 0%, 100% 100%, 0% 100%);
          clip-path: polygon(7% 0%, 93% 0%, 100% 100%, 0% 100%);
}
.serviceBox:after {
  width: 80%;
  height: 15px;
  border-radius: 0 0 10px 10px;
  top: auto;
  bottom: 0;
  -webkit-clip-path: none;
          clip-path: none;
}
.serviceBox .service-content {
  background: #fff;
  padding: 25px 20px;
  border-radius: 10px;
  margin: 0 5%;
}
.serviceBox .service-content:before {
  content: "";
  background: #0c1248;
  background: var(--mainBlue);
  width: 128px;
  height: 100px;
  transform: translateX(-50%);
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-clip-path: polygon(0 0, 100% 0, 50% 100%);
          clip-path: polygon(0 0, 100% 0, 50% 100%);
}
.serviceBox .service-icon {
  color: #77787a;
  background: linear-gradient(to left, #dedfe1, #f3f3f3);
  font-size: 40px;
  line-height: 100px;
  width: 100px;
  height: 100px;
  margin: 0 auto 20px;
  border-radius: 50%;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.03);
  position: relative;
  z-index: 1;
}
.serviceBox .service-icon:before {
  content: "";
  background: linear-gradient(to right, #dedfe1, #f3f3f3);
  width: 88%;
  height: 88%;
  border-radius: 50%;
  box-shadow: 5px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
}
.serviceBox .title {
  color: #1e5270;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 0 0 10px;
}
.serviceBox .description {
  font-size: 1.2rem;
  margin: 0;
}
.container .servicesH3 h3 {
  text-align: center;
  font-size: 1.5rem !important;
  padding: 1% 5px 1%;
}
.container .card {
  position: relative;
  border-radius: 10px;
  background: transparent;
  border: solid 1px transparent;
}

.container .card .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f00;
  transition: 0.7s;
  z-index: 1;
}

.container .card .icon1 {
  background: linear-gradient(
    45deg,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d
  );
}

.container .card .icon2 {
  background: #2d8cff;
}

.container .card .icon3 {
  background: #14b81c;
}

.container .card .icon .fa img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  transition: 0.7s;
  color: #fff;
}

i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 80px;
  transition: 0.7s;
  color: #fff;
}

.container .card .face {
  width: 300px;
  height: 150px;
  transition: 0.5s;
}

.container .card .face.face1 {
  position: relative;
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
  margin: 0 auto;
}

.container .card:hover .face.face1 {
  background: #ff0057;
  transform: translateY(0px);
}

.container .card .face.face1 .content {
  opacity: 1;
  transition: 0.5s;
}
.golfRow {
  width: 100%;
  display: flex;
  margin-bottom: 4%;
}
.golf-btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: 1px;
  letter-spacing: var(--mainSpacing);
  color: #fff;
  color: var(--mainWhite);
  background: #d03737 !important;
  background: var(--primaryColor) !important;
  padding: 0.4rem 0.9rem;
  border: 3px solid #d03737 !important;
  border: 3px solid var(--primaryColor) !important;
  transition: all 0.3s linear;
  transition: var(--mainTransition);
  cursor: pointer;
  font-size: 25px;
  text-align: center;
  margin: 30px auto;
}
.golf-btn-primary:hover {
  background-color: #0c1248 !important;
  background-color: var(--mainBlue) !important;
  color: #d03737;
  color: var(--primaryColor);
}
.bookButton {
  padding: 1rem 2rem;
}
.container .card:hover .face.face1 .content {
  opacity: 1;
}

.container .card .face.face1 .content i {
  max-width: 100px;
}

.container .card .face.face2 {
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
  transform: translateY(-50px);
  margin: 0 auto;
}

.container .card:hover .face.face2 {
  transform: translateY(0);
}

.container .card .face.face2 .content p {
  margin: 0;
  padding: 0;
  text-align: center;
  color: #414141;
}

.container .card .face.face2 .content h3 {
  margin: 0 0 10px 0;
  padding: 0;
  color: #fff;
  font-size: 24px;
  text-align: center;
  color: #414141;
}

.servicesBanner {
  display: inline-block;
  background: #cfcfcf;
  color: #d03737;
  color: var(--primaryColor);
  padding: 2rem 1rem;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: 1px;
  letter-spacing: var(--mainSpacing);
  width: 100%;
  margin-top: 2rem;
}
.golfBanner h1 {
  padding-top: 5rem !important;
}
.servicesBanner h1 {
  font-size: 3rem;
  font-family: "Audiowide", cursive;
  padding-top: 3rem;
}

.servicesBanner div {
  width: 10rem;
  height: 5px;
  background: #0c1248;
  background: var(--mainBlue);
  margin: 1.5rem auto 0;
}
.workImage {
  width: 90%;
  margin-left: 15%;
}
@media screen and (min-width: 768px) {
  #servicesContainer {
    max-width: 850px !important;
  }
}
@media screen and (min-width: 992px) {
  #servicesContainer {
    max-width: 950px !important;
  }
}
@media screen and (min-width: 1054px) {
  #servicesContainer {
    max-width: 1004px !important;
  }
}
@media only screen and (max-width: 990px) {
  .serviceBox {
    margin: 0 0 30px;
  }
  .nigelImages {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
  }
  .nigelImages img {
    width: 90%;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 992px) {
  #basic-navbar-nav {
    background: #b2b2b2f2;
  }
  #navbar {
    padding: 20px 0%;
  }
  #logo {
    margin-left: 20px;
    margin-bottom: 15px;
  }
  .navbar-light .navbar-toggler {
    margin-right: 20px;
  }
  #basic-navbar-nav a {
    text-decoration: none;
    margin-left: 20px;
  }
}
@media only screen and (max-width: 450px) {
}
span svg {
  font-size: 2.5rem;
  color: #d03737;
  color: var(--primaryColor);
}

/* Footer */

.footer-section {
  background: rgb(27, 27, 27);
  position: relative;
  text-align: center;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta svg {
  color: #d03737;
  color: var(--primaryColor);
  font-size: 30px;
  /* float: left; */
  margin-top: 1.2rem;
  position: relative;
  /* left: 85px; */
  /* bottom: 10px; */
  top: -40px;
}
.cta-text {
  /* padding-left: 15px; */
  display: inline-block;
  margin-bottom: 1rem;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.cta-text span {
  color: #b2b2b2;
  color: var(--mainGrey);
  font-size: 15px;
}
.cta-text p {
  color: #b2b2b2;
  color: var(--mainGrey);
  font-size: 15px;
}
.cta-text a {
  color: #b2b2b2;
  color: var(--mainGrey);
  font-size: 15px;
  text-decoration: underline;
  transition: 0.5s;
}
.cta-text a:hover {
  color: #fff;
  color: var(--mainWhite);
  font-size: 16px;
  transition: 0.5s;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
  max-width: 200px;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.795);
  border-radius: 30px;
  margin-top: 2rem;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  /* Underline Styling */
  -webkit-text-decoration: 3px underline;
          text-decoration: 3px underline;
  -webkit-text-decoration-color: #d03737;
          text-decoration-color: #d03737;
  -webkit-text-decoration-color: var(--primaryColor);
          text-decoration-color: var(--primaryColor);
  text-underline-offset: 10px;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
  border-radius: 15px;
}
.footer-social-icon svg {
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 38px;
  /* border-radius: 50%; */
}
.email-bg svg {
  fill: #fff;
  fill: var(--mainWhite);
}
.email-bg > svg:hover {
  fill: #d03737;
  fill: var(--primaryColor);
  transition: 1s;
}
.linkedin-bg svg {
  fill: #fff;
  fill: var(--mainWhite);
}
.linkedin-bg svg:hover {
  fill: #2867b2;
  transition: 1s;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 40px;
  position: relative;
  text-align: center;
  /* Underline Styling */
  -webkit-text-decoration: 3px underline;
          text-decoration: 3px underline;
  -webkit-text-decoration-color: #d03737;
          text-decoration-color: #d03737;
  -webkit-text-decoration-color: var(--primaryColor);
          text-decoration-color: var(--primaryColor);
  text-underline-offset: 25px;
}
.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover {
  color: #d03737;
  color: var(--primaryColor);
}
.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}
.footer-widget span {
  padding-top: 2rem;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  color: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #ff5e14;
  padding: 13px 20px;
  border: 1px solid #ff5e14;
  top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area {
  background: #202020;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}
.copyright-text p a {
  color: white;
  transition: 0.7s;
}
.copyright-text p a:hover {
  color: #d03737;
  color: var(--primaryColor);
  transition: 0.7s;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a {
  color: #d03737;
  color: var(--primaryColor);
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

